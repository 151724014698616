import React from 'react'
import {Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMapMarked, faClock, faMailBulk } from '@fortawesome/free-solid-svg-icons'

const RightColumn = () => (
    <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>

      <h4>Locatia Noastra</h4>

      <p>
        <FontAwesomeIcon icon={faMapMarked} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> <strong>Adresa:</strong> Bulevardul Unirii, Nr.44/10A, Baia Mare , Maramures</span>
      </p>

      <p>
        <FontAwesomeIcon icon={faPhone} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> <strong>Telefon:</strong> 0745 662 356</span>
      </p>

      <p>
        <FontAwesomeIcon icon={faPhone} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> <strong>Telefon:</strong> 0744 554 106</span>
      </p>

      <p>
        <FontAwesomeIcon icon={faPhone} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> <strong>Telefon:</strong> 0744 228 475</span>
      </p>

      <p>
        <FontAwesomeIcon icon={faPhone} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> <strong>Telefon:</strong> 0747 092 798</span>
      </p>

      <p>
        <FontAwesomeIcon icon={faMailBulk} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> <strong>Email: </strong> peninsulaimpex@yahoo.ro</span>
      </p>
          
      <hr/>

      <h4>Orar</h4>

      <p>
        <FontAwesomeIcon icon={faClock} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> Luni-Joi 08:00 -- 16:30</span>
      </p>

      <p>
        <FontAwesomeIcon icon={faClock} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> Vineri 08:00 -- 15:00</span>
      </p>

      <p>
        <FontAwesomeIcon icon={faClock} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> Sambata-Duminica - inchis</span>
      </p>

      <p>
        <FontAwesomeIcon icon={faClock} color='#0088cc' font='normal normal normal 14px/1'/>
        <span> Sarbatori legale - inchis</span>
      </p>

      <hr/>
    </Col>
)

export default RightColumn