import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {DataContext} from '../Context'
import Header from '../Home/Header'

import './Products.css'

export class Products extends Component {

    static contextType = DataContext;
    state = {
        search : ""
    }

    onchange = e => {
        this.setState({search : e.target.value});
    }

    render() {
        const {search} = this.state;
        const {products} = this.context;
        const filteredProduct = products.filter( product => {
            return product.name.toLowerCase().indexOf( search.toLowerCase() ) !== -1 
        })

        if (typeof filteredProduct === 'undefined' || filteredProduct.length < 1) {
            return (<div> 
                        <span>Cautare </span>
                        <input onChange={this.onchange}/>
                        <h1> Nu au fost gasite rezultate </h1>
                    </div>)
        }

        return (
            <div>
            <Header/>
            <div className="productSection">
                <span>Cautare </span>
                <input onChange={this.onchange}/>
                <div id='product'>
                {   
                    filteredProduct.map(product =>{
                        return(
                                <div className="card" key={product._id}>
                                    <Link to={`/product/${product._id}`}>
                                        <img src={product.images[0]} alt=""/>
                                    </Link>
                                    <div className="content">
                                        <h3>
                                            <Link to={`/product/${product._id}`}>{product.name}</Link>
                                        </h3>
                                        <p>{product.description}</p>
                                    </div>
                                </div>
                    )})
                }
                </div>
            </div>
            </div>
        )
    }
}

export default Products