import React, { Component } from 'react'
import Products from './Section/Products'
import Details from './Section/Details'
import {Route, Redirect, Switch} from 'react-router-dom'
import Home from './Home/Home'
import Contact from './Contact/Contact'

export class Section extends Component {
    render() {
        return (
            <section>
                <Switch>
                    <Route path="/" component={Home} exact/>
                    <Route path="/product" component={Products} exact/>
                    <Route path="/product/:id" component={Details}/>
                    <Route path="/contact" component={Contact} exact/>
                    <Route render={() => <Redirect to={{pathname: "/"}} />} />
                </Switch>
            </section>
        )
    }
}

export default Section