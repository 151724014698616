import React, { Component } from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import Toolbar from './components/Toolbar/Toolbar';
import SideDrawer from './components/SideDrawer/SideDrawer'
import Backdrop from './components/Backdrop/Backdrop'
import Footer from './components/Footer/Footer'
import Section from './components/Section'
import {DataProvider} from './components/Context'

class App extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
        backdrop = <Backdrop click={this.backdropClickHandler}/>
    }

    return (
      <DataProvider>
       <div className="app" style={{height: '100%'}}>
          <Router>
            <Toolbar drawerClickHandler={this.drawerToggleClickHandler}/>
            <SideDrawer show={this.state.sideDrawerOpen}/>
            {backdrop}
            <Section />
            <Footer/>
          </Router>
        </div>
      </DataProvider>
    );
  }
}

export default App;
