import React, { Component } from 'react'
import Header from './Header'
import GridColumns from './GridColumns'


export class Home extends Component {
    render() {
        return (
            <div>
                <Header/>
                <br/>
                <br/>
                <hr/>
                <GridColumns/>
                <br/>
                <br/>
            </div>
        )
    }
}

export default Home