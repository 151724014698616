import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import RightColumn from '../RightColumn'

import './GridColumns.css'

const GridColumns = () => (
  <Container fluid className="gridContainer">
  <Row>
    <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
      <h2>DESPRE NOI</h2>
      <br/>
      <p>S.C PENINSULA IMPEX S.R.L., infiintata in anul 2007, ofera solutii complete de amabalare si produse abrazive, fiind unul din cei mai mari importatori si distribuitori in domeniu din Maramures.</p>
      <p>Pentru a satisface nevoile clientilor in materie de ambalaje si produse abrazive, firma noastra ofera produse de o calitate superioara la preturi competitive.</p>
      <p>Experienta, seriozitatea si dedicarea echipei noastre a dus la cresterea continua a cifrei de afaceri si a numarului de clienti multumiti.</p>
      <p>Va asteptam sa ne contactati, ca sa va convingeti ca suntem partenerul pe care il cautati!</p>

      <div className='conclusion'>
          <FontAwesomeIcon icon={faStar} color='#0088cc' font='normal normal normal 14px/1'/>
          <h4> De +13 ani pe piata din Romania</h4>
      </div>
    </Col>
    <RightColumn/>
  </Row>
</Container>
)

export default GridColumns