import React, { Component } from 'react'

export const DataContext = React.createContext();

export class DataProvider extends Component {

    state = {
        products: [
              {
                "_id": "1",
                "name": "Folie cu bule",
                "type": "folie",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/folieBule1.jpg", "/img/folieBule2.jpg", "/img/folieBule3.jpg","/img/folieBule4.jpg", "/img/folieBule5.jpg"]
              }, {
                "_id": "2",
                "name": "Folie expandata (PEE)",
                "type": "folie",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/folieExpandata1.jpg", "/img/folieExpandata2.jpg", "/img/folieExpandata3.jpg", "/img/folieExpandata4.jpg"]
              }, {
                "_id": "3",
                "name": "Folie cu bule verde",
                "type": "folie",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/folieVerde1.jpg", "/img/folieVerde2.jpg", "/img/folieVerde3.jpg", "/img/folieVerde4.jpg"]
              }, {
                "_id": "4",
                "name": "Folie cu bule + PEE (Airbol)",
                "type": "folie",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/airbol2.jpg", "/img/airbol1.jpg", "/img/airbol3.jpg"]
              }, {
                "_id": "5",
                "name": "Folie stretch automata",
                "type": "folie",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/folieAutomata2.jpg", "/img/folieAutomata1.jpg", "/img/folieAutomata3.jpg"]
              }, {
                "_id": "6",
                "name": "Folie stretch manuala",
                "type": "folie",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/folieManuala1.jpg", "/img/folieManuala2.jpg", "/img/folieManuala3.jpg", "/img/folieManuala4.jpg"]
              }, {
                "_id": "7",
                "name": "Folie stretch manuala neagra",
                "type": "folie",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/folieNeagra1.jpg"]
              }, {
                "_id": "8",
                "name": "Folie stretch manuala pretensionata",
                "type": "folie",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/foliePretensionataManual1.jpg", "/img/foliePretensionataManual2.jpg", "/img/foliePretensionataManual3.jpg"]
              }, {
                "_id": "81",
                "name": "Folie termocontractibila",
                "type": "folie",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/folieTermoContractibila1.jpg", "/img/folieTermoContractibila2.jpg", "/img/folieTermoContractibila3.jpg"]
              }, {
                "_id": "9",
                "name": "Scotch britte",
                "type": "abraziv",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/scotchBritte1.jpg", "/img/scotchBritte2.jpg", "/img/scotchBritte3.jpg"]
              }, {
                "_id": "10",
                "name": "Rola carton ondulat",
                "type": "carton",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/rolaCarton3.jpg", "/img/rolaCarton2.jpg", "/img/rolaCarton1.jpg", "/img/rolaCarton4.jpg"]
              }, {
                "_id": "11",
                "name": "Coltare carton",
                "type": "carton",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/coltarCarton1.jpg", "/img/coltarCarton2.jpg", "/img/coltarCarton3.jpg"]
              }, {
                "_id": "12",
                "name": "Profile de tip L PEE",
                "type": "profile",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/coltar1.jpg", "/img/coltar2.jpg", "/img/coltar3.jpg", "/img/coltar4.jpg", "/img/coltar5.jpg"]
              }, {
                "_id": "13",
                "name": "Profile de tip U PEE",
                "type": "profile",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/profilU1.jpg", "/img/profilU2.jpg", "/img/profilU3.jpg", "/img/profilU4.jpg", "/img/profilU5.jpg", "/img/profilU6.jpg"]
              }, {
                "_id": "14",
                "name": "Saci",
                "type": "ambalare",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/saci1.jpg", "/img/saci2.jpg", "/img/saci3.jpg"]
              }, {
                "_id": "15",
                "name": "Pungi Airball",
                "type": "ambalare",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/plic1.jpg", "/img/plic2.jpg", "/img/plic3.jpg", "/img/plic4.jpg"]
              }, {
                "_id": "16",
                "name": "Abraziv suport hartie ASS",
                "type": "abraziv",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/abrazivSuportHartie1.jpg", "/img/abrazivSuportHartie2.jpg", "/img/abrazivSuportHartie3.jpg"]
              }, {
                "_id": "17",
                "name": "Abraziv suport textil",
                "type": "abraziv",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/abrazivSuportTextil1.jpg", "/img/abrazivSuportTextil2.jpg"]
              }, {
                "_id": "18",
                "name": "Burete abraziv",
                "type": "abraziv",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/bureteAbraziv1.jpg", "/img/bureteAbraziv2.jpg", "/img/bureteAbraziv3.jpg"]
              }, {
                "_id": "19",
                "name": "Banda PP neagra",
                "type": "banda de legat",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/bandaPPT1.jpg", "/img/bandaPPT2.jpg"]
              }, {
                "_id": "20",
                "name": "Banda PP alba",
                "type": "banda de legat",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/bandaPPTAlba1.jpg", "/img/bandaPPTAlba2.jpg", "/img/bandaPPTAlba3.jpg"]
              }, {
                "_id": "21",
                "name": "Banda PET",
                "type": "banda de legat",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/bandaPet1.jpg"]
              }, {
                "_id": "22",
                "name": "Banda metalica",
                "type": "banda de legat",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/bandaMetalica1.jpg"]
              }, {
                "_id": "23",
                "name": "Banda adeziva Scotch (acrilic transparent)",
                "type": "scotch",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/acrilic1.jpg", "/img/acrilic2.jpg"]
              }, {
                "_id": "24",
                "name": "Banda adeziva Scotch (acrilic maro)",
                "type": "scotch",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/acrilicMaro1.jpg", "/img/acrilicMaro2.jpg", "/img/acrilicMaro3.jpg"]
              }, {
                "_id": "25",
                "name": "Banda adeziva Scotch (hot melt)",
                "type": "scotch",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/hotMelt1.jpg", "/img/hotMelt2.jpg", "/img/hotMelt3.jpg"]
              }, {
                "_id": "26",
                "name": "Banda adeziva Scotch (solvent maro)",
                "type": "scotch",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/solventMaro1.jpg", "/img/solventMaro2.jpg", "/img/solventMaro3.jpg"]
              }, {
                "_id": "27",
                "name": "Banda adeziva Scotch (solvent transparent)",
                "type": "scotch",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/solvent1.jpg", "/img/solvent2.jpg", "/img/solvent3.jpg"]
              }, {
                "_id": "271",
                "name": "Banda de mascare",
                "type": "banda",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/bandaMascare1.jpg", "/img/bandaMascare2.jpg", "/img/bandaMascare3.jpg"]
              }, {
                "_id": "28",
                "name": "Servetele din expandat",
                "type": "ambalare",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/serveteleExpandat1.jpg", "/img/serveteleExpandat2.jpg", "/img/serveteleExpandat3.jpg", "/img/serveteleExpandat4.jpg"]
              }, {
                "_id": "29",
                "name": "Derulator folie strech manuala",
                "type": "ambalare",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/derulatorFolieStrechManuala1.jpg", "/img/derulatorFolieStrechManuala2.jpg", "/img/derulatorFolieStrechManuala3.jpg"]
              }, {
                "_id": "30",
                "name": "Dispenser banda adeziva (scotch) H75-CP",
                "type": "dispozitiv",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/dispenserBanda1.jpg", "/img/dispenserBanda2.jpg", "/img/dispenserBanda3.jpg"]
              }, {
                "_id": "31",
                "name": "Dispenser banda adeziva (scotch) H11-CP",
                "type": "dispozitiv",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/dispenserBandaR1.jpg", "/img/dispenserBandaR2.jpg", "/img/dispenserBandaR3.jpg", "/img/dispenserBandaR4.jpg"]
              }, {
                "_id": "32",
                "name": "Dispozitiv de legat banda PP si PET",
                "type": "dispozitiv",
                "description": "Pentru detalii tehnice si comenzi va rugam sa ne contactati.",
                "images": ["/img/dispozitivDeLegat1.jpg", "/img/dispozitivDeLegat2.jpg", "/img/dispozitivDeLegat3.jpg", "/img/dispozitivDeLegat4.jpg"]
              }
        ]
    }

    render() {
        const {products} = this.state;
        return (
           <DataContext.Provider value={{products}}>
               {this.props.children}
           </DataContext.Provider>
        )
    }
}
