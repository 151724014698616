import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarked, faPhone } from '@fortawesome/free-solid-svg-icons'
import './Footer.css';

const footer = props => (
    <footer className="footer">
        <div className="container">           
            <div className="column" data-aos="fade-right" data-aos-delay="200">
                <h2>Contacteaza-ne</h2>
                <p>
                    <FontAwesomeIcon icon={faMapMarked}/> Adresa: Bulevardul Unirii, Nr.44/10A, Baia Mare , Maramures
                </p>
                <p>
                    <FontAwesomeIcon icon={faPhone}/>
                    <strong> Telefon:</strong> 0745 662 356
                </p>
                <p>
                    <FontAwesomeIcon icon={faPhone}/> 
                    <strong> Telefon:</strong> 0744 554 106
                </p>
                <p>
                    <FontAwesomeIcon icon={faPhone}/>
                    <strong> Telefon:</strong> 0744 228 475
                </p>
                <p>
                    <FontAwesomeIcon icon={faPhone}/>
                    <strong> Telefon:</strong> 0747 092 798
                </p>
            </div>
        </div>
    </footer>
);


export default footer;