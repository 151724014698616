import React from 'react'
import {Container, Row} from 'react-bootstrap'
import RightColumn from '../RightColumn'
import ContactForm from './ContactForm'

const ContactGridColumns = () => (
  <Container fluid className="gridContainer">
  <Row>
    <ContactForm/>
    <RightColumn/>
  </Row>
</Container>
)

export default ContactGridColumns