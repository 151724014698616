import React, { Component } from 'react'
import {DataContext} from '../Context'
import './Details.css'

import 'react-slideshow-image/dist/styles.css'
import ProductImage from './ProductImage';

export class Details extends Component {
    static contextType = DataContext;
    state = {
        product: []
    }

    getProduct = () =>{
        if(this.props.match.params.id){
            const res = this.context.products;
            const data = res.filter(item =>{
                return item._id === this.props.match.params.id
            })
            this.setState({product: data})
        }
    };

    componentDidMount(){
        this.getProduct();
    }

    render() {
        const {product} = this.state;
        return (
            <div>
                <h1>PENINSULA IMPEX SRL</h1>
                <hr/>
                {
                    product.map(item =>(
                        <div className="details" key={item._id}>
                            <div className="carusel">
                                <ProductImage detail={product}/>
                            </div>
                            <div className="box">
                                <div className="row">
                                    <h2>{item.name}</h2>
                                    <span>{item.type}</span>
                                </div>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))       
                }
            </div>
        )
    }
}

export default Details