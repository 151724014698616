import React, {Component} from 'react'
import {Col} from 'react-bootstrap'
import emailjs from 'emailjs-com'

export class ContactForm extends Component{
    state = {
        name: '',
        email: '',
        message: ''
    }
    

    onNameChange(event) {
        this.setState({name: event.target.value})
    }
    
    onEmailChange(event) {
        this.setState({email: event.target.value})
    }
    
    onMessageChange(event) {
        this.setState({message: event.target.value})
    }
    
    handleSubmit(e) {
        e.preventDefault();
        emailjs.send('gmail','template_cd6pa6f', {
            from_name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            },'user_AukJLevPgyKtZiHESbn3i')
        .then(function(response) {
            if (response.status === 200){
                alert("Mesaj trimis cu succes.");
            } else {
                alert("Mesaj nu a putut fi trimis. Va rugam contactati-ne telefonic!");
            }
         });
    }
    
    render() {
        return(
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
                <h2><strong>Contacteaza-ne</strong></h2>
                <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                    <div className="form-group">
                        <label htmlFor="name">Nume</label>
                        <input type="text" className="form-control" onChange={this.onNameChange.bind(this)}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Adresa e-mail</label>
                        <input type="email" className="form-control" aria-describedby="emailHelp" onChange={this.onEmailChange.bind(this)}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Mesaj</label>
                        <textarea className="form-control" rows="8" onChange={this.onMessageChange.bind(this)}></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">Trimite</button>
                </form>
            </Col>
        )
    }
}

export default ContactForm