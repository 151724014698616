import React from 'react';

import './Header.css'

const Header = () => {
    return(
        <section className="site-title">
            <div className="site-background" data-aos="fade-up" data-aos-delay="100">
                <h2>Produse profesionale</h2>
                <h1>Pentru o afacere de succes</h1>
                <button className="btn" onClick={() => window.location.href='https://maps.google.com/?daddr=Peninsula%20Impex,%20Ferma%2014,%20Hala%2010,%20DN%20Varianta%20de%20ocolire%20Baia-Mare'}>Locatie</button>
            </div>
        </section>
    );
};

export default Header;