import React, { Component } from 'react'
import Header from '../Home/Header'
import ContactGridColumns from './ContactGridColumns'

export class Contact extends Component {
    render() {
        return (
            <div>
                <Header/>
                <br/>
                <br/>
                <hr/>
                <ContactGridColumns/>
                <br/>
                <br/>
            </div>
        )
    }
}

export default Contact